import history from "../history";
import AppRoutes from "./Routes";
import { CustomRouter } from "./CustomRouter";
import { paths } from "../common/constants/Rutas";
import { SettingsProvider } from "../providers/settings";

function AppRouter() {
  return (
    <CustomRouter basename={paths.basename} history={history}>
        <SettingsProvider>
            <AppRoutes />
        </SettingsProvider>
    </CustomRouter>
  );
}

export default AppRouter;
