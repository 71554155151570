import React from "react";

export const TextField = ({
  name,
  placeholder,
  onChange,
  maxLength,
  handleFocused,
  isFocused,
  type,
  readOnly,
  value
}) => {
  const handleControlChange = (e, value) => {
    if(onChange) {
      onChange(e, value);
    }
  }

  return (
    <>
      { !readOnly && 
        <input
          id={name}
          placeholder={placeholder}
          onChange={handleControlChange}
          maxLength={maxLength}
          className={`captura${isFocused ? " captura-focused" : ""}`}
          onFocus={() => handleFocused(true)}
          onBlur={() => handleFocused(false)}
          value={value}
          type={type}
        />
      }
      { readOnly && 
        <p style={{textAlign: "center", marginBottom: "0px"}}>{value}</p>
      }
    </>
  );
};

export default TextField;
