import React from "react";
import {
  Card,
  Spacer,
} from "../../components";

export const NotFound = (props) => {
  return (
    <>
      <Spacer size={40} />
      <Card titulo={"No encontrado"}>
            <div className="en-construccion" />
        </Card>
    </>
  );
};

export default NotFound;
