import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { paths } from "../../common/constants/Rutas";
import HomeLayout from "../../layouts/Home";

import NotFound from "../../containers/NotFound";
import EnConstruccion from "../../containers/EnConstruccion";
import Boletos from "../../containers/Boletos";
import Colaborador from "../../containers/Colaborador";
import Contacto from "../../containers/Contacto";
import Dashboard from "../../containers/Dashboard";
import Inicio from "../../containers/Inicio";

function AppRoutes() {
  const location = useLocation();

  const getPagina = () => {
    let page;
    let hashHasParams = (location?.hash.indexOf('?') > 0);
    let hash = location?.hash.substring(0, hashHasParams ? location?.hash.indexOf('?') : location?.hash.length); 
    switch(hash) {
      case paths.sorteos:
        page = <EnConstruccion key={location.pathname} titulo="Sorteos" />;
        break;
      case paths.boletos:
        page = <Boletos key={location.pathname} />;
        break;
      case paths.colaboradores:
        page = <Colaborador key={location.pathname} titulo="Verificar colaborador" />;
        break;
      case paths.contacto:
        page = <Contacto key={location.pathname} titulo="Contactanos" />;
        break;
      case paths.miCuenta:
        page = <Dashboard key={location.pathname} titulo="Mi Cuenta" />;
        break;
      default:
        page = <Inicio key={location.pathname} titulo="Bienvenido" />;
    }
    return page;
  }
  const [pagina, setPagina] = useState(getPagina());

  useEffect(() => {
    setPagina(getPagina());
    // eslint-disable-next-line
  }, [location?.hash])

  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route
          path={"/"}
          name="Inicio"
          element={ pagina }
        />
        <Route 
          path="*" 
          name="Not Found" 
          element={<NotFound />} 
        />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
