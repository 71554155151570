import React from "react";
import { Card } from "../card";

export const NoEncontrado = (props) => {
  return (
    <Card>
      <div className={"sin-boletos"} >
        <div className={"sin-boletos-imagen"}></div>
        <p className={"sin-boletos-label"}>{props?.desc}</p>
      </div>
    </Card>
  );
};

export default NoEncontrado;
