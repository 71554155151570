import React from "react";
import {
  Card,
  Spacer,
  ContactCard
} from "../../components";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

export const Contacto = (props) => {
  /** UI */
  return (
    <>
      <Spacer size={40} />
      <Card titulo={props.titulo}>
        <Spacer size={10} />
        <p className="contact-label">Puedes ponerte en contacto con nosotros por medio de nuestras redes sociales, para conocer informacón adicional, resolver dudas o apoyo con soporte técnico.</p>
        <ContactCard
          icon={<FacebookIcon/>}
          url={"https://www.facebook.com/ladelpesito"}
          text={"@ladelpesito"}
        />
        <ContactCard
          icon={<WhatsAppIcon/>}
          url={"https://wa.me/5216632947602"}
          text={"66-32-947602"}
        />
        <ContactCard
          icon={<EmailIcon/>}
          url={"mailto:soporte@ladelpesito.com.mx"}
          text={"soporte@ladelpesito.com.mx"}
        />
      </Card>
    </>
  );
};
export default Contacto;