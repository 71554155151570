import React from "react";

export const Card = ({
  titulo,
  ...props
}) => {
  return (
    <div className="card">
      { titulo && <p className="titulo">{titulo}</p>}
      {props.children}
    </div>
  );
};

export default Card;
