import React from "react";

export const ArrowDown = (props) => {

  return (
    <div style={{
        width: "100%",
        height: "20px"
    }}
    >
      <span className="material-symbols-rounded" style={ {marginLeft: "calc(50% - 12px)" } }>expand_more</span>
    </div>
  );
};

export default ArrowDown;
