import React from "react";

export const ContactCard = ({
  icon,
  text,
  url
}) => {
  return (
    <div className="contact-card">
      <div className="contact-card-desc">
        <a className={"contact-card-link"} href={url} target={"_blank"}>{icon} {text}</a>
      </div>
    </div>
  );
};

export default ContactCard;
