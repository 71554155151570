import './App.css';
import AppRouter from './router';

/** App */
function App() {  
  return (
    <AppRouter />
  );
}

export default App;