import React from "react";

export const NumberField = ({
  name,
  placeholder,
  onChange,
  maxLength,
  handleFocused,
  isFocused,
  value
}) => {
  const handleControlChange = (e, value) => {
    if(onChange) {
      onChange(e, value);
    }
  }

  return (
    <input
        id={name}
        type={"tel"}
        placeholder={placeholder}
        onChange={handleControlChange}
        maxLength={maxLength}
        className={`captura${isFocused ? " captura-focused" : ""}`}
        onFocus={() => handleFocused(true)}
        onBlur={() => handleFocused(false)}
        value={value}
    />
  );
};

export default NumberField;
