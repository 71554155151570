import React from "react";

export const ActionButton = ({
  nombre,
  onClick,
  label,
  icon,
  disabled
}) => {

  const handleOnClick = (e, value) => {
    if(onClick) {
      onClick(e, value);
    }
  }

  return (
    <button
      id={nombre}
      disabled={disabled}
      onClick={handleOnClick}
      className={"action-button captura"}
    >{label}</button>
  );
};

export default ActionButton;
