import React from "react";
import { 
  Card,
  Chip,
  Spacer
} from "../index";
import {colaboradorStatus} from "../../common/constants/Status"

export const ColaboradorCard = ({
  colaborador,
  codigo,
  ...props
}) => {
  return (
    <Card>
      <div className={"colaborador-nombre"}>{colaborador.nombre}</div>
      <Chip item={colaboradorStatus[colaborador.estatus]} />
      <img alt={""} className={"colaborador-imagen"} src={`./media/colaboradores/${codigo}.png`} />
      <Spacer size={20} />
    </Card>
  );
};

export default ColaboradorCard;
